import React, { useState } from 'react';
import '../../Static/Style/UploadVideosCss/SingleVideo.css';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BlobServiceClient, Credential } from '@azure/storage-blob';
// import { Buffer } from 'buffer';

// window.Buffer = Buffer;


const UploadVideo = ({ onClose }) => {
  const { uploadVideo, getAllVideos, getSasToken,setUploadProgress,uploadProgress } = useVideoStore();
  const { currentUser } = useAccountStore();
  const [finishedOrError, setFinishedOrError] = useState(false);


  const [formData, setFormData] = useState({
    videoFile: null,
    thumbnailFile: null,
    title: '',
    description: '',
    tags: '',
    category: '',
    privacy: 'public',
  });

  const [previewUrl, setPreviewUrl] = useState('');
  // const [uploadProgress, setUploadProgress] = useState(0);
  const maxChars = 900;

  const handleFileChange = (e) => {
    const { id, files } = e.target;
    const file = files[0];

    const allowedVideoExtensions = ['.mp4', '.avi', '.mkv', '.mov', '.wmv'];

    if (id === 'videoFile' && file) {
      const fileExtension = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);

      if (!allowedVideoExtensions.includes('.' + fileExtension.toLowerCase())) {
        toast.error('Invalid file type. Please upload a video file (MP4, AVI, MKV, MOV, WMV).');
        e.target.value = '';
        return;
      }

      setFormData((prevData) => ({ ...prevData, [id]: file }));
      setPreviewUrl(URL.createObjectURL(file));
    } else {
      setFormData((prevData) => ({ ...prevData, [id]: file }));
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleDescriptionChange = (e) => {
    const { value } = e.target;
    if (value.length <= maxChars) {
      setFormData((prevData) => ({ ...prevData, description: value }));
    }
  };

  const getCSRFToken = () => {
    let cookieValue = null;
    const name = 'csrftoken';
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.videoFile) {
      toast.error('Select a video file.');
      return;
    }
    if (!formData.title) {
      toast.error('Provide a title.');
      return;
    }
    const userID = currentUser.id;
    const ChannelID = localStorage.getItem('selectedId');
    if (!ChannelID) {
      toast.error('Please Select Channel.');
      return;
    }
    const data = new FormData();
    if (formData.thumbnailFile) {
      data.append('thumbnail', formData.thumbnailFile);
    }
    data.append('name', formData.title);
    data.append('description', formData.description);
    data.append('tags', formData.tags);
    data.append('category', formData.category);
    data.append('video_type', formData.privacy);
    data.append('user', userID);
    data.append('channel', ChannelID);

    try {
      const sasResponse = await getSasToken({ blob_name: formData.videoFile.name });
  
      if (sasResponse.status !== 200) {
          throw new Error('Failed to get SAS token.');
      }
  
      const { sas_token, blob_url, blob_name } = sasResponse.data;
      const blobServiceClient = new BlobServiceClient(`${blob_url}?${sas_token}`);
      const containerClient = blobServiceClient.getContainerClient('videos/videos');
      const blockBlobClient = containerClient.getBlockBlobClient(blob_name);

      toast.info('Uploading started...');
      onClose();
  
        const file = formData.videoFile;
        const fileSize = file.size;
        const blockSize = 10 * 1024 * 1024; 
        let offset = 0;
        let blockId = 0;
        let lastLoggedProgress = 0;  


   const generateBlockId = (blockNum) => {
        const idString = blockNum.toString().padStart(5, '0');
        return btoa(idString);
    };

    while (offset < fileSize) {
        const chunkSize = Math.min(blockSize, fileSize - offset);
        const chunk = file.slice(offset, offset + chunkSize);

        await blockBlobClient.stageBlock(generateBlockId(blockId), chunk, chunkSize);

        offset += chunkSize;
        blockId += 1;

        const process = Math.round((offset / fileSize) * 100);
        setUploadProgress(process);

        if (process !== lastLoggedProgress) {
            console.log(`Upload progress: ${process}%`);
            lastLoggedProgress = process;
        }
    }

    const blockList = Array.from({ length: blockId }, (_, i) => generateBlockId(i));
    
    await blockBlobClient.commitBlockList(blockList, {
        blobHTTPHeaders: {
            blobContentType: file.type,
        }
    });
      
          data.append('video', `videos/videos/${blob_name}`);
          await uploadVideo(data);
          toast.success('Video uploaded successfully!');
          getAllVideos(); 
     
  
  } catch (error) {
      const errorMessage = error.response && error.response.data
          ? error.response.data
          : error.message;
  
      toast.error(`Error: ${errorMessage}`);
  }
  

   
  };    

  const currentCharCount = formData.description.length;
  const remainingChars = maxChars - currentCharCount;

  return (
    <>
      <div className="yu-upload-popup-overlay"></div>
      <div className="yu-upload-popup">
        <h2>Upload Video</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="yu-upload-form-group">
            {/* {uploadProgress > 0 && (
              <div className="progress-overlay">
                <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
              </div>
            )} */}
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="videoFile">Select Video File <span style={{color:"red"}}>*</span></label>
            <input type="file" id="videoFile" accept="video/*" onChange={handleFileChange} />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="thumbnailFile">Select Video Thumbnail<span style={{color:"red"}}>*</span></label>
            <input type="file" id="thumbnailFile" accept="image/*" onChange={handleFileChange} />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="title">Video Title<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              id="title"
              placeholder="Enter video title"
              value={formData.title}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="description">Video Description<span style={{color:"red"}}>*</span></label>
            <textarea
              id="description"
              placeholder="Add a description for your video"
              value={formData.description}
              onChange={handleDescriptionChange}
              maxLength={maxChars}
              required
            ></textarea>
            <div className={`char-count ${remainingChars < 0 ? 'red' : ''}`}>
              {remainingChars < 0 ? `-${Math.abs(remainingChars)}` : remainingChars}
            </div>
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="tags">Tags <span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              id="tags"
              placeholder="Enter tags"
              value={formData.tags}
              onChange={handleInputChange}
            />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="category">Category<span style={{color:"red"}}>*</span></label>
            <input
              type="text"
              id="category"
              placeholder="Enter category"
              value={formData.category}
              onChange={handleInputChange}
            />
          </div>
          <div className="yu-upload-form-group">
            <label htmlFor="privacy">Video Privacy</label>
            <select id="privacy" value={formData.privacy} onChange={handleInputChange}>
              <option value="public">Public</option>
              <option value="private">Private</option>
            </select>
          </div>
          <div className="yu-upload-form-group-button">
            <button type="button" className="close-button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit">Upload Video</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UploadVideo;
