import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Static/Style/CreateChannel.css';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';
import "../../Static/Style/ChannelCss/UpdateChannel.css";

const UpdateChannel = ({ onClose, channel }) => {
    const [channelName, setChannelName] = useState('');
    const [channelDescription, setChannelDescription] = useState('');
    const [loading, setLoading] = useState(false); 
    const navigate = useNavigate();
    const { updateChannel } = useChannelStore();
    const { currentUser } = useAccountStore();
    const [profileImage, setProfileImage] = useState(null);

    console.log()

    useEffect(() => {
        if (channel) {
            setChannelName(channel.name || '');
            setChannelDescription(channel.description || '');
        }
    }, [channel]);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handlePreviewClick = () => {
        document.getElementById('channelProfile').click();
    };

    const handleUpdateChannel = async (event) => {
        event.preventDefault();
        setLoading(true);
    
        try {
            if (!currentUser || !currentUser.id) {
                throw new Error('Please login first');
            }
    
            const token = localStorage.getItem('authToken');
            if (!token) {
                throw new Error('Authentication error');
            }
    
            if (!channelName || !channelDescription) {
                toast.error('Please fill out all fields');
                setLoading(false);
                return;
            }
    
            const formData = new FormData();
            formData.append('description', channelDescription);
            formData.append('name', channelName);
            formData.append('user', currentUser.id);
            if (profileImage) {
                const blob = await fetch(profileImage).then(res => res.blob());
                formData.append('profile', blob, 'profile.jpg');  
            }
    
            const response = await updateChannel(channel.id, formData);
    
            console.log("Channel Updated:", response);
            toast.success("Channel Updated Successfully");
            onClose();
            navigate(`/channel-list/${currentUser.name}`);
    
        } catch (error) {
            console.error('Error updating channel:', error);
    
            if (error.response && error.response.data) {
                const errorData = error.response.data;
    
                const message = errorData.name ? 
                    `${errorData.name.join(', ')}` : 
                    errorData.description ? 
                        `${errorData.description.join(', ')}` : 
                        errorData.user ? 
                            `User Error: ${errorData.user.join(', ')}` : 
                            errorData.message || 'Update Channel failed, Please Try Again';
    
                toast.error(message);
            } else {
                toast.error(error.message || 'Update Channel failed, Please Try Again');
            }
        } finally {
            setLoading(false);
        }
    };
    
    
    

    return (
        <div className='update-channel-overlay'> 
            <div className='update-channel-form'>
                <h2>Update Channel</h2>
                <span className='close-modal' style={{fontWeight: "700"}} onClick={onClose}><span className='yu-app-cross-icon'></span></span>

                <form onSubmit={handleUpdateChannel}>
                    <div className='create-channel-form-group'>
                        <div className='channel-profile-preview' onClick={handlePreviewClick}>
                            {profileImage ? (
                                <img src={profileImage} alt='Channel Preview' title='Click to select Another Profile' />
                            ) : (
                                <span>No image selected</span>
                            )}
                        </div>
                        <input
                            id='channelProfile'
                            type='file'
                            accept="image/*"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className='create-channel-form-group'>
    <label htmlFor='channelName'>Channel Name</label>
    <input
        type='text'
        id='channelName'
        value={channelName}
        onChange={(e) => setChannelName(e.target.value)}
        required
    />
</div>
<div className='create-channel-form-group'>
    <label htmlFor='channelDescription'>Description</label>
    <textarea
        id='channelDescription'
        value={channelDescription}
        onChange={(e) => setChannelDescription(e.target.value)}
        required
    />
</div>

                    <button type='submit' disabled={loading}>
                        {loading ? 'Updating...' : 'Update Channel'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default UpdateChannel;
