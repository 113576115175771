import React, { useState } from 'react';
import '../../Static/Style/SopportCss/Support.css'; 

const Support = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setIsSubmitted(true);
  };

  return (
    <div className='support-con-overlay'>
      <div className="support-container">
        <div className='close-sopport' title='close' onClick={onClose}>
          <span className='yu-app-cross-icon'></span>
        </div>

        {isSubmitted ? (
          <div className="thank-you-message">
            <span className="tick-icon">&#10003;</span>
            <h2>Thank You!</h2>
            <p>Your issue has been submitted successfully.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="support-form">
            <h2>Your Issue</h2>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <button type="submit" className="submit-btn">Submit</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Support;
