import React from 'react';
import '../Static/Style/TermsConditionsCss/TermsConditions.css';

function TermsAndConditions() {
  return (
    <div className="terms-and-conditions-box">
      <div className="terms-and-conditions">
        <h2>Terms and Conditions with Bangalore, India as the jurisdiction</h2>

        <h3>Colabrary Terms and Conditions</h3>

        <h4>1. Introduction</h4>
        <p>
          Welcome to Colabrary! These Terms and Conditions govern your use of our platform. By accessing or using Colabrary, you agree to comply with these terms.
        </p>

        <h4>2. Educational Content Policy</h4>
        <p>
          <strong>Content Requirements:</strong> Users must only upload educational content relevant to learning and academic topics. All non-educational, explicit, violent, or inappropriate content is prohibited.
        </p>
        <p><strong>Prohibited Content Includes but is not limited to:</strong></p>
        <ul>
          <li>Sexual, explicit, or pornographic material.</li>
          <li>Hate speech, discriminatory remarks, or content promoting violence or illegal activities.</li>
          <li>Any content unrelated to educational purposes.</li>
        </ul>
        <p><strong>Consequences:</strong> Violations may result in account suspension, permanent ban, legal actions, and penalties up to ₹5 lakhs.</p>

        <h4>3. Copyright and Intellectual Property</h4>
        <p>
          <strong>User Responsibility:</strong> Ensure all uploaded content is original or properly licensed. Unauthorized use of copyrighted materials is forbidden.
        </p>
        <p><strong>Colabrary’s Rights:</strong> We reserve the right to remove infringing content and pursue legal action against repeat offenders.</p>

        <h4>4. User Accounts</h4>
        <p>
          <strong>Registration:</strong> Users must provide accurate information during registration. You are responsible for maintaining the confidentiality of your account credentials.
        </p>
        <p><strong>Termination:</strong> Colabrary may terminate or suspend accounts for violations of these terms without notice.</p>

        <h4>5. Privacy and Data Protection</h4>
        <p>
          <strong>Data Collection:</strong> We collect personal data necessary to provide our services and improve user experience.
        </p>
        <p><strong>Data Use:</strong> Your data will be used in accordance with our Privacy Policy and not shared with third parties without consent.</p>
        <p><strong>Security:</strong> We implement reasonable security measures to protect your data from unauthorized access.</p>

        <h4>6. User Conduct</h4>
        <p><strong>Respect and Safety:</strong> Users must engage respectfully and refrain from any form of harassment or abusive behavior.</p>
        <p><strong>Reporting Violations:</strong> Report any violations of these terms using our reporting system. We will address reports promptly.</p>

        <h4>7. Liability and Indemnity</h4>
        <p>
          <strong>User Liability:</strong> You agree to indemnify Colabrary against any claims or damages arising from your use of the platform.
        </p>
        <p><strong>Limitation of Liability:</strong> Colabrary is not liable for any indirect or consequential damages arising from your use of the platform.</p>

        <h4>8. Changes to Terms</h4>
        <p>
          <strong>Modifications:</strong> Colabrary may update these Terms and Conditions periodically. Continued use of the platform constitutes acceptance of any changes.
        </p>

        <h4>9. Governing Law and Jurisdiction</h4>
        <p>
          <strong>Jurisdiction:</strong> These terms are governed by and construed in accordance with the laws of Bangalore, India. Disputes will be resolved in the competent courts of Bangalore, India.
        </p>
      </div>
    </div>
  );
}

export default TermsAndConditions;
