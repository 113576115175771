import React, { useEffect, useRef } from 'react';
import '../../Static/Style/UploadVideosCss/UploadNotify.css';

const UploadingNotify = ({ progressList = [], onClose, onProgress }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className='upload-progress-con' ref={popupRef}>
      <h4>Video Upload Status</h4>

      {progressList.length > 0 ? (
        progressList.map((progress, index) => (
          <div key={index} className="upload-progress-item">
            <div className="upload-progress-bar">
              <div className="progress" style={{ width: `${progress}%` }}>
                {progress}%
              </div>
            </div>

            <div className="uploaded-files">
              {progress < 100 ? (
                <div>Video {index + 1} Uploading... {progress}%</div> 
              ) : (
                <div>Video {index + 1} Upload Complete!</div>
              )}
            </div>
          </div>
        ))
      ) : (
        // Display progress based on onProgress if progressList is empty
        onProgress > 0 && (
          <div className="upload-progress-item">
            <div className="upload-progress-bar">
              <div className="progress" style={{ width: `${onProgress}%` }}>
                {onProgress}%
              </div>
            </div>

            <div className="uploaded-files">
              {onProgress < 100 ? (
                <div>Video Uploading... {onProgress}%</div> 
              ) : (
                <div>Upload Complete!</div>
              )}
            </div>
          </div>
        )
      )}

      {progressList.length === 0 && onProgress === 0 && <div>No upload started.</div>}

      <div className="actions">
        <button className="cancel-btn" onClick={onClose}>
          <span className='yu-app-cross-icon'>✕</span>
        </button>
      </div>
    </div>
  );
};

export default UploadingNotify;
