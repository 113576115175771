import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useVideoStore } from '../../Store/UserStore/VideoStore'; 
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import ReactPlayer from 'react-player'; 
import moment from 'moment';
import '../../Static/Style/YuSearchResult.css';
import MyLoader from '../Loader/MyLoader';
import LoadingErrorPage from '../LoadingErrorPage/LoadingErrorPage';
import { mediaUrl } from '../../Config';

const YuSearchResults = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [channelData, setChannelData] = useState({});
  const [query, setQuery] = useState('');
  const location = useLocation();
  const { AllSearchBar } = useVideoStore();
  const { getSingleChannel } = useChannelStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const navigate = useNavigate();

  const handleVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  const toggleMenu = (id) => {
    setActiveMenu(activeMenu === id ? null : id);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get('q') || '';
    setQuery(searchQuery);

    const fetchData = async () => {
      setLoading(true); 
      setError(null);

      try {
        const results = await AllSearchBar(searchQuery); 
        if (results && Array.isArray(results)) {
          setSearchResults(results);
          
          const uniqueChannelIds = new Set(results.map(result => result.channel));
          const fetchedChannelData = {};

          // Fetch all channel data for each unique channel
          for (const channelId of uniqueChannelIds) {
            const channelResponse = await getSingleChannel(channelId);
            if (channelResponse) {
              fetchedChannelData[channelId] = channelResponse;
            }
          }

          setChannelData(fetchedChannelData); // Store all channel data
        } else {
          setSearchResults([]);
        }

      } catch (error) {
        console.error('Error fetching search results:', error);
        setError('Failed to load results'); 
        setSearchResults([]); 
      } finally {
        setLoading(false);
      }
    };

    if (searchQuery) {
      fetchData();
    } else {
      setLoading(false); 
    }
  }, [location.search, AllSearchBar, getSingleChannel]); 

  if (loading) {
    return <div className="loader-container"><MyLoader /></div>; 
  }

  if (error) {
    return <LoadingErrorPage />; 
  }

  return (
    <div className='search-results-container'>
      <div className="search-results-box">
        {Array.isArray(searchResults) && searchResults.length > 0 ? (
          searchResults.map((result) => (
            <div key={result.id} className="search-result-item" onClick={() => handleVideoClick(result.id)}>
              <div className='list-search-video'>
                <ReactPlayer
                  url={result.video}
                  light={mediaUrl+`${result.thumbnail}`}
                  controls={false}
                  width='100%'
                  height='100%'
                />
              </div>
              <div className="result-details">
                <h3 className="result-title">
                  {result.name.length > 80 ? `${result.name.slice(0, 80)}...` : result.name}
                </h3>
                <div className="result-meta">
                  <span>0 views • {moment(result.timestamp).fromNow()}</span>
                  {channelData[result.channel] && (
                    <div className='result-meta-channel'>
                        <img 
                        src={mediaUrl+`${channelData[result.channel].profile}`} 
                        alt="Channel Profile" 
                        className="channel-profile"
                      />
                      <p className='channel-name'>{channelData[result.channel].name}</p>
                    
                      
                    </div>
                  )}
                </div>
                <p className="result-description">
                  {result.description.length > 100 ? `${result.description.slice(0, 100)}...` : result.description}
                </p>
              </div>
              {/* <span 
                className="search-result-setting-dots-icon" 
                onClick={(e) => {
                  e.stopPropagation();
                  toggleMenu(result.id);
                }}
              ></span> */}

              {activeMenu === result.id && (
                <div className="options-menu" style={{ position: "relative" }}>
                  <ul style={{ position: "absolute" }}>
                    <li>Save Watch Later</li>
                    <li>Save to Playlist</li>
                    <li>Share</li>
                    <li>Report</li>
                  </ul>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No results found</p>
        )}
      </div>
    </div>
  );
};

export default YuSearchResults;
