import React, { useState, useRef } from 'react';
import '../../Static/Style/YuLeftComponet.css';
import YuVideos from './YuVideos';

const YuLeftComponent = () => {
  const [activeTab, setActiveTab] = useState('All'); 
  const sliderRef = useRef(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className='yu-left-side'>
      <div className='yu-categorys' ref={sliderRef}>
        {/* <h5
          className={activeTab === 'All' ? 'active-tab' : ''}
          onClick={() => handleTabClick('All')}
        >
          All
        </h5> */}
        {/* <h5
          className={activeTab === 'Education' ? 'active-tab' : ''}
          onClick={() => handleTabClick('Education')}
        >
          Education
        </h5> */}
        {/* Add more categories as needed */}
      </div>

      <div className='yu-videos-container'>
        <YuVideos />
      </div>
    </div>
  );
};

export default YuLeftComponent;
