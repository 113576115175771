import React, { useState, useRef, useEffect } from 'react';
import siteLogo from '../../assets/colabrary-logo.png';
import siteMobileLogo from '../../assets/colabrary-logo-2.png';
import '../../Static/Style/yuTopNav.css';
import UploadVideo from '../UploadVideos/UploadVideo';
import MultiUploadVideos from '../UploadVideos/MultiUploadVideos';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import { useSocketStore } from '../../Store/UserStore/Soket';
import NotificationPopup from '../NotificationPopup/NotificationPopup';
import UploadingNotify from '../UploadVideos/UploadingNotify'

const YuTopNav = ({ toggleRightComponent, isRightComponentVisible, profileImage, isLoggedIn }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isUploadPopupVisible, setIsUploadPopupVisible] = useState(false);
  const [isMultiUploadPopupVisible, setIsMultiUploadPopupVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const popupRef = useRef(null);
  const navigate = useNavigate();
  const { currentUser, userNotifications } = useAccountStore();
  const { AllSearchBar, uploadProgress, multipleUploadProgress } = useVideoStore();
  const [notifications, setNotifications] = useState([]);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [isUploadProgressVisible, setIsUploadProgressVisible] = useState(false);
  const { newNotification, connectSocket } = useSocketStore();
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [uploadStarted, setUploadStarted] = useState(false); 
  


  useEffect(() => {
    async function fetchNotifications() {
      if (currentUser) {
        setLoading(true);
        try {
          const getnotification = await userNotifications({ user: currentUser.id });
          setNotifications(getnotification.data);

          if (getnotification.data) {
            const NotificationCount = getnotification.data.filter((notification) => !notification.read).length;
            setUnreadNotificationsCount(NotificationCount);
          }
        } catch (err) {
          console.error("Failed to fetch notifications", err);
        } finally {
          setLoading(false);
        }
      }
    }
    fetchNotifications();
  }, [currentUser, userNotifications]);

  useEffect(() => {
    if (currentUser) {
      connectSocket();
    }
  }, [connectSocket, currentUser]);

  useEffect(() => {
    if (newNotification) {
      setNotifications((prevNotifications) => [newNotification, ...prevNotifications]);
      setUnreadNotificationsCount((prevCount) => prevCount + 1);
    }
  }, [newNotification]);

  const markAsRead = () => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => ({ ...notification, read: true }))
    );
    setUnreadNotificationsCount(0);
  };

  const togglePopup = () => {
    setIsPopupVisible((prev) => !prev);
  };

  const toggleNotification = () => {
    setIsNotificationVisible((prev) => !prev);
  };

  const toggleUploadProgress = () => {
    console.log("Toggling upload progress visibility");
    setIsUploadProgressVisible((prev) => !prev);
  };
  const closeNotificationPopup = () => {
    setIsNotificationVisible(false);
  };
  const closeUploadProgressPopup = () => {
    setIsUploadProgressVisible(false);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
      setIsUploadPopupVisible(false);
      setIsNotificationVisible(false);
      setIsUploadProgressVisible(false);
    }
  };

  const handleSearchbar = async () => {
    if (!searchQuery) return;
    try {
      await AllSearchBar(searchQuery);
      navigate(`/search-video/?q=${encodeURIComponent(searchQuery)}`);
    } catch (error) {
      console.error('Error fetching search results:', error);
      toast.error('Failed to fetch search results');
    }
  };

  const openUploadPopup = () => {
    if (!isLoggedIn) {
      toast.error('Please Login First');
      return;
    }
    setIsUploadPopupVisible(true);
    setIsPopupVisible(false);
  };

  const openMultiUploadPopup = () => {
    if (!isLoggedIn) {
      toast.error('Please Login First');
      return;
    }
    setIsMultiUploadPopupVisible(true);
    setIsPopupVisible(false);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    const isAnyUploadInProgress = multipleUploadProgress.some(progress => progress > 0 && progress < 100);
    
    const areAllUploadsZeroOrComplete = multipleUploadProgress.every(progress => progress === 0 || progress === 100);
    
    const isUploadProgressOngoing = uploadProgress > 0 && uploadProgress < 100;
    
    const isUploadProgressComplete = uploadProgress === 100;
  
    if (isAnyUploadInProgress || isUploadProgressOngoing) {
      setUploadStarted(true);
      console.log('Current Upload Progress:', multipleUploadProgress);
    } else if (areAllUploadsZeroOrComplete && !isUploadProgressOngoing) {
      setUploadStarted(false);
      console.log('All uploads are either 0 or complete');
    }
  }, [uploadProgress, multipleUploadProgress]);
  
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchbar(); 
    }
  };



  return (
    <>
      <div className='yu-top-nav'>
        <div className='yu-site-logo' onClick={handleLogoClick} title='logo'>
          <img src={siteLogo} alt="Desktop Logo" className="desktop-logo" />
          <img src={siteMobileLogo} alt="Mobile Logo" className="mobile-logo" />
        </div>
        
        <div className='yu-search-box'>
          <input 
            type="search" 
            placeholder='Search' 
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleKeyPress} 
          />
          <button className='searchButton yu-searchtextButton' onClick={handleSearchbar}>
            <span className='yu-searchBox-icon' title='Search'></span>
          </button>
          {/* <button className='searchVoiceButton'>
            <span className='yu-search-voice-icon' title='Search With Your Voice'></span>
          </button> */}
        </div>

        <div className='yu-top-left-icon'>
          <div className='create-yu' onClick={togglePopup}>
            <span className='create-yu-icon' title='Create'></span>
          </div>
          {isLoggedIn && (
          <div className='yu-notifications' onClick={toggleNotification}>
            <span className='notifications-icon' title='Notifications'></span>
            {unreadNotificationsCount > 0 && (
              <span className='notification-count'>{unreadNotificationsCount}</span>
            )}
          </div>
          )}

          {isLoggedIn && (

<div className='yu-notifications' onClick={toggleUploadProgress}>
            {uploadStarted ? (
              <span className='yu-uploading-loop-icon' title='Upload Status'></span>

            ) : (
              <span className='yu-uploading-normal-icon' title='Upload Status'></span>
              // <span className='yu-uploading' title='Upload Status'>{uploadProgress}</span>


            )}
          </div>
          )}

          {isRightComponentVisible ? (
            <span onClick={toggleRightComponent} className='yu-toggle-close' title='Close'><span className='yu-app-cross-icon'></span></span>
          ) : (
            isLoggedIn ? (
              <div className="yu-user-menu-profile" onClick={toggleRightComponent} title='Profile'>
                <img src={currentUser.profile || profileImage} alt="User Profile" className="profile-image" />
              </div>
            ) : (
              <span onClick={toggleRightComponent} className='yu-toggle-menu' title='Menu'></span>
            )
          )}
        </div>
      </div>

      {isPopupVisible && (
        <div className='create-popup' ref={popupRef}>
          <div className='create-popup-option' onClick={openUploadPopup}>Upload Video</div>
          <div className='create-popup-option' onClick={openMultiUploadPopup}>
            Upload Multiple Videos  
            {/* <span style={{ fontSize: "10px", position: "relative", top: "-5px", color: "var(--lightgray)" }}>(Coming Soon)</span> */}
          </div>
          <div className='create-popup-option'>
            <span style={{cursor:"not-allowed"}}>Go Live
              <span style={{ fontSize: "10px", position: "relative", top: "-5px", color: "var(--lightgray)" }}>(Coming Soon)</span>
            </span>
          </div>
        </div>
      )}

      {isUploadPopupVisible && (
        <UploadVideo onClose={() => setIsUploadPopupVisible(false)} />
      )}
      
      {isMultiUploadPopupVisible && (
        <MultiUploadVideos onClose={() => setIsMultiUploadPopupVisible(false)} />
      )}

      {isNotificationVisible && (
        <NotificationPopup 
          notifications={notifications}
          markAsRead={markAsRead}
          onClose={closeNotificationPopup} 
          loading={loading}
        />
      )}

        {isUploadProgressVisible && (
        <UploadingNotify
          onProgress={uploadProgress}
          progressList={multipleUploadProgress}
          onClose={closeUploadProgressPopup}
        />
      )}
    </>
  );
};

export default YuTopNav;
