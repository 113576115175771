
export const mediaUrl = ''

export const fileUrl = 'https://yu.azureedge.net/'

export const url = 'https://api.colabrary.com/yu/'
export const urlLogin = 'https://api.colabrary.com/'
export const soketURL = "wss://api.colabrary.com/ws/"

// export const url = 'http://127.0.0.1:8000/yu/'
// export const urlLogin = 'http://127.0.0.1:8000/'
// export const soketURL = 'ws://127.0.0.1:8000/ws/'

export const MessageType ={
    LIKE_VIDEO:'LIKE_VIDEO',
    DISLIKE_VIDEO:'DISLIKE_VIDEO',
    COMMENT_VIDEO:'COMMENT_VIDEO',
    CHANNEL_CREATED:'CHANNEL_CREATED',
    CHANNEL_UPDATED:'CHANNEL_UPDATED',
    LIKE_COMMENT:'LIKE_COMMENT',
    CHANNEL_SUBSCRIBED:'CHANNEL_SUBSCRIBED',
    UPLOAD_VIDEO:'UPLOAD_VIDEO',
}
