import { create } from 'zustand';
import axios from 'axios';
import {url}  from '../../Config'
import {urlLogin}  from '../../Config'



export const useAccountStore = create((set) => ({
    currentUser: null,
    isLoggedIn: false,
    authToken:null,
    currentNotifications:null,

    userLogin: async (payload) => {

      try {
        const response = await axios.post(urlLogin+'auth/login-user/', payload);
        console.log(response);
  
        const token = response.data.token;
        localStorage.setItem('authToken', token);
  
        set({ currentUser: response.data.user, isLoggedIn: true, authToken: token });
        return response;
      } catch (error) {
        console.error('Error during login:', error.response ? error.response.data : error.message);
        throw error;
      }
      },

      createUser: async (payload) => {
        try {
          const response = await axios.post(urlLogin+'auth/create-user/', payload);
          console.log(response);
          localStorage.setItem('authToken', response.data.token);
          return response;
        } catch (error) {
          console.error('Error creating user:', error);
          throw error;
        }
      },
    
      generateMobileOtp: async (contact) => {
        try {
          const response = await axios.get(urlLogin+`auth/phone-otp-generate/${contact}/`);
          console.log('Mobile OTP generated:', response.data);
          return response.data;
        } catch (error) {
          console.error('Error generating mobile OTP:', error);
          throw error;
        }
      },
    
      generateEmailOtp: async (email) => {
        try {
          const response = await axios.get(urlLogin+`auth/email-otp-generate/${email}/`);
          console.log('Email OTP generated:', response.data);
          return response;
        } catch (error) {
          console.error('Error generating email OTP:', error);
          throw error; 
        }
      },
                /////// user data fetch////////////////
 userDataGet: async ()=>{
                  const token = localStorage.getItem('authToken');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(urlLogin+'auth/auth-user/', {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      console.log(response);
      set({ currentUser: response.data, isLoggedIn: true });
    } catch (error) {
      console.error('Error fetching user data:', error);
      throw error;
    }
    },

  userNotifications: async ()=>{
    const token = localStorage.getItem('authToken');
if (!token) {
return;
}

try {
const response = await axios.get(url+'get-all-notifications/', {
headers: {
Authorization: `Token ${token}`,
},
});

console.log(response);
set({ currentNotifications: response.data,});
return response;
} catch (error) {
console.error('Error fetching user data:', error);
throw error;
}
},

    userlogout: () => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('selectedId');

      set({ currentUser: null, isLoggedIn: false, authToken: null});
      window.location.reload();
    
    },
            

}))