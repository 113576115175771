import { Routes, Route } from 'react-router-dom';
import React, { useState } from 'react';
import YuContainer from '../Components/YuContainer/YuContainer';
import YuSingleVideo from '../Components/YuContainer/YuSingleVideo';
import '../Static/Style/YuPage.css';
import OwnChannel from '../Components/Channel/OwnChannel';
import ChannelList from '../Components/Channel/ChannelList';
import YuTopNav from '../Components/YuContainer/YuTopNav';
import YuRightComponent from '../Components/YuContainer/YuRightComponent';
import YuSearchResults from '../Components/YuContainer/YuSearchResults';
import BottomNav from '../Components/MobilleBottomNav/BottomNav';

const YuPage = () => {
  const [isRightComponentVisible, setIsRightComponentVisible] = useState(false);

  const toggleRightComponent = () => {
    setIsRightComponentVisible(!isRightComponentVisible);
  };

  const [profileImage, setProfileImage] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleProfileImageChange = (image, loggedIn) => {
      setProfileImage(image);
      setIsLoggedIn(loggedIn);
  };

  return (
    <div className="Yu-box">
      <div
        className={`yu-top-nav-con ${
          isRightComponentVisible ? 'visible' : 'hidden'
        }`}
      >
        <YuTopNav
          toggleRightComponent={toggleRightComponent}
          isRightComponentVisible={isRightComponentVisible} 
          profileImage={profileImage} isLoggedIn={isLoggedIn}
        />
      </div>
      <div className="yu-right-container">
        <div
          className="right-box"
          style={{
            width: isRightComponentVisible ? '70%' : '100%',
          }}
        >
          <Routes>
            <Route path="/" element={<YuContainer />} />
            <Route path="/channel-list/" element={<ChannelList/>} />

            <Route path="channel/:channelName/:channelId" element={<OwnChannel />} />
            <Route path="video/:videoId" element={<YuSingleVideo
                      profileImage={profileImage} />} />
            <Route path="search-video/" element={<YuSearchResults />} />

          </Routes>
        </div>
        <div
          className={`yu-right-component ${
            isRightComponentVisible ? 'visible' : 'hidden'
          }`}
        >
          <YuRightComponent
          onProfileImageChange={handleProfileImageChange}  />
        </div>
      </div>
      <footer> <BottomNav
              toggleRightComponent={toggleRightComponent}
              isRightComponentVisible={isRightComponentVisible} 
              profileImage={profileImage} isLoggedIn={isLoggedIn}
      
      /></footer>
    </div>
  );
};

export default YuPage;
