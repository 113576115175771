import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import '../../Static/Style/OwnChannel.css';
import moment from 'moment';
import MyLoader from '../Loader/MyLoader';
import LoadingErrorPage from '../LoadingErrorPage/LoadingErrorPage';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import { useNavigate, useParams } from 'react-router-dom';
import UpdateChannel from '../Channel/UpdateChannel'
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { mediaUrl } from '../../Config';

const OwnChannel = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('Home');
  const navigate = useNavigate();
  const { channelId } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false); 


  const { getChannelVideos, currentChannelVideo, getSingleChannel, currentChannel } = useChannelStore();
  const { isLoggedIn, currentUser } = useAccountStore();
  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleVideoClick = (videoId) => {
    navigate(`/video/${videoId}`);
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded); 
  };

  useEffect(() => {
    const fetchChannelOwnVideo = async () => {
      setLoading(true);
      try {
        await getSingleChannel(channelId);
        await getChannelVideos(channelId);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
        setError('Error fetching data');
        setLoading(false);
      }
    };

    fetchChannelOwnVideo();
  }, [getChannelVideos, channelId]);

  if (loading) {
    return <div className='full-page-loader'><MyLoader /></div>;
  }

  if (error) {
    return <div><LoadingErrorPage /></div>;
  }
  const sortedVideos = currentChannelVideo ? [...currentChannelVideo].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)) : [];

  const isChannelOwner = isLoggedIn && currentUser && currentChannel && currentUser.id === currentChannel.user;


  return (
    <div className='own-channel-container'>
      <div className='own-channel'>
        <div className='own-channel-header'>
          <div className='channel-header'>
            <div className='channel-img'>
              <img
                src={mediaUrl+`${currentChannel.profile}`}
                alt="Channel Profile"
              />
            </div>
            <h1>{currentChannel.name}</h1>

            <div className='channel-info'>
              <p>

                {isExpanded ? currentChannel.description : `${currentChannel.description.slice(0, 180)}...`}
                <span onClick={handleToggle} style={{ margin: "0 10px", color: "var(--black)" }}>
                  {isExpanded ? 'less' : 'more'}
                </span>
              </p>
            </div>

          </div>
          {isChannelOwner && (
            <div className='channel-buttons'>
              <button onClick={togglePopup}>Edit channel</button>
              {/* <button>Manage Videos</button> */}
            </div>
          )}


          <div className='channel-nav'>
            <ul>
              <li className={activeTab === 'Home' ? 'active-tab' : ''} onClick={() => setActiveTab('Home')}>Home</li>
              <li className={activeTab === 'Videos' ? 'active-tab' : ''} onClick={() => setActiveTab('Videos')}>Videos</li>
              <li className={activeTab === 'Playlist' ? 'active-tab' : ''} onClick={() => setActiveTab('Playlist')}>Playlist</li>
            </ul>
          </div>
        </div>

        {/* Home Tab Content */}
        {activeTab === 'Home' && (
          <div className='channel-content'>
            <h3>Home</h3>
            <div className='channel-content-all'>
              {sortedVideos.length > 0 ? (
                sortedVideos.map((video) => (
                  <div
                    key={video.id}
                    onClick={() => handleVideoClick(video.id)}
                    className='content-all-video-item'
                  >
                    <ReactPlayer
                      url={mediaUrl+`${video.video}`}
                      light={mediaUrl+`${video.thumbnail}`}


                      width='100%'
                      height='150px'
                    />
                    <div className='video-info'>
                      <h4 className='video-title'>{video.name}</h4>
                      <p className='video-details'>
                        {video.views} views • {moment(video.timestamp).fromNow()}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No videos available.</p>
              )}
            </div>
          </div>
        )}

        {/* Videos Tab Content */}
        {activeTab === 'Videos' && (
          <div className='channel-content'>
            <h3>Uploads</h3>
            <div className='channel-content-all'>
              {sortedVideos.length > 0 ? (
                sortedVideos.map((video) => (
                  <div
                    key={video.id}
                    onClick={() => handleVideoClick(video.id)}
                    className='content-all-video-item'
                  >
                    <ReactPlayer
                      url={mediaUrl+`${video.video}`}
                      light={mediaUrl+`${video.thumbnail}`}
                      width='100%'
                      height='150px'
                    />

                    <div className='video-info'>
                      <h4 className='video-title'>{video.name}</h4>
                      <p className='video-details'>0 views • {moment(video.timestamp).fromNow()}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No videos available.</p>
              )}
            </div>
          </div>
        )}

        {activeTab === 'Playlist' && (
          <div className='channel-content'>
            <h3>Playlist</h3>
            <p>This is where playlists would be shown.</p>
          </div>
        )}

        {isPopupOpen && currentChannel && (
          <div className="popup-content">
            <UpdateChannel
              channel={currentChannel}
              onClose={togglePopup}
            />
          </div>
        )}

      </div>
    </div>
  );
};

export default OwnChannel;
