import { create } from 'zustand';
import { soketURL } from '../../Config';
import { MessageType } from '../../Config';

export const useSocketStore = create((set, get) => ({
  state: null,
  newNotification: null,
  socketClosed: false,
  lastProcessedMessageId: null,

  connectSocket: () => {
    const token = localStorage.getItem('authToken');
    
    if (!token) {
      console.error('No token found for WebSocket connection');
      return;
    }

    if (get().state) {
      console.warn('WebSocket is already connected');
      return;
    }

    const ws = new WebSocket(`${soketURL}notification?token=${token}`);
    console.log('WebSocket initiated:', ws);

    ws.onopen = () => {
      console.log('Connected to WebSocket.');
      set({ state: ws, socketClosed: false });
    };

    ws.onclose = (e) => {
      console.log(
        `Socket is closed. Reconnect will be attempted in 1 second.`,
        e.reason
      );
      set({ state: null, socketClosed: true });

      setTimeout(() => {
        get().connectSocket();
      }, 1000); // You can use a backoff strategy here for exponential delays
    };

    ws.onmessage = (e) => {
      try {
        const messageData = JSON.parse(e.data);
        const parsedMessage = JSON.parse(messageData.message);

        // Prevent processing duplicate messages
        if (get().lastProcessedMessageId === parsedMessage.data.data.id) {
          return;
        }

        console.log('New notification:', parsedMessage.data.data);

        if (
          parsedMessage.data?.type &&
          Object.values(MessageType).includes(parsedMessage.data.type)
        ) {
          set({ 
            newNotification: parsedMessage.data, 
            lastProcessedMessageId: parsedMessage.data.data.id 
          });
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error);
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket Error:', error);
      set({ state: null });
    };
  },
}));
