import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import '../../Static/Style/YuVideoList.css';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import moment from 'moment';
import { mediaUrl } from '../../Config';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';

const YuVideoList = ({ onVideoSelect, videoId }) => {
  const { recommendationVideos, getAllVideos } = useVideoStore();
  const { getSingleChannel } = useChannelStore();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const navigate = useNavigate();
  const [channelData, setChannelData] = useState({});

  useEffect(() => {
    const fetchRecommendationVideo = async () => {
      try {
        const recommendationResponse = await getAllVideos();
        const recommendations = recommendationResponse.data;
        setRecommendations(recommendations);
        const uniqueChannelIds = [...new Set(recommendations.map(video => video.channel))];

        const channelDataPromises = uniqueChannelIds.map(channelId => getSingleChannel(channelId));
        const channelResponses = await Promise.all(channelDataPromises);
  
        const fetchedChannelData = {};
        channelResponses.forEach((channelResponse, index) => {
          const channelId = uniqueChannelIds[index];
          if (channelResponse && channelResponse) {
            fetchedChannelData[channelId] = channelResponse;
          } 
  
          // console.log(`Channel ID: ${channelId}, Data: `, channelResponse);
        });
  
        setChannelData(fetchedChannelData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching recommendation videos:', error.response ? error.response.data : error.message);
        setError('Error fetching videos');
        setLoading(false);
      }
    };
  
    fetchRecommendationVideo();
  }, [getAllVideos, videoId, getSingleChannel]);


  

  
  
  const handleVideoSelect = (videoId) => {
    navigate(`/video/${videoId}`);

    if (onVideoSelect) {
      onVideoSelect(videoId);
    }
  };

  if (loading) {
    return (
      <div className="yu-spinner-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <span className="yu-spinners-small"></span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="yu-error-message">
        <span>{error}</span>
      </div>
    );
  }

  return (
    <div className="yu-video-list">
      {recommendations && recommendations.length > 0 ? (
        recommendations.map((video) => (
          <div
            key={video.id}
            className="list-video-details"
            onClick={() => handleVideoSelect(video.id)}
          >
            <div className="list-video-player">
              <ReactPlayer
                url={mediaUrl + `${video.video}`}
                light={mediaUrl + `${video.thumbnail}`}
                controls={false}
                width="100%"
                height="100%"
              />
            </div>
            <div className="list-video-details-info">
              <h5>{video.name}</h5>
              <span className="channelName">{channelData[video.channel]?.name || 'Unknown Channel'}</span>
              <div>
                <span className="views">{video.views} views</span>
                <span className="uploadTime">{moment(video.timestamp).fromNow()}</span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="yu-no-videos">No videos available</div>
      )}
    </div>
  );
};

export default YuVideoList;
