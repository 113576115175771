import React from 'react'
import '../../Static/Style/SharePopupCss/SharePopup.css'

const SharePopup = ({ link, onClose }) => (
    <div className="yu-share-popup-overlay" >
        <div className="yu-share-popup">
        <button  className="close-popup" onClick={onClose}>x</button>

            <h3>Share this video</h3>
            <div className='share-link-input'>           
                <input type="text" value={link} readOnly />
            <span onClick={() => navigator.clipboard.writeText(link)}>Copy</span>
            </div>
 
            <div className="yu-share-options">
                <button>Facebook</button>
                <button>Twitter</button>
                <button>Email</button>
            </div>
        </div>
    </div>
);


export default SharePopup
