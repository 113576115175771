import React, { useState } from 'react';
import '../../Static/Style/MobileBottomNavCss/MobileNav.css';
import { useNavigate } from 'react-router-dom';

const BottomNav = ({ toggleRightComponent, profileImage, isLoggedIn }) => {
  const [activeTab, setActiveTab] = useState('home');
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    
    if (tab === 'home') {
      navigate('/'); 
      window.location.reload();
    } else if (tab === 'profile') {
      toggleRightComponent();
    }
  };

  return (
    <nav className="mobile-bottom-nav">
      <ul>
        {/* Home tab */}
        <li onClick={() => handleTabClick('home')}>
          {activeTab === 'home' ? (
            <span className="mobile-home-icon-solid mobile-icon"></span>
          ) : (
            <span className="mobile-home-icon mobile-icon"></span>
          )}
        </li>

        {/* Short video tab */}
        <li onClick={() => handleTabClick('shorts')}>
          {activeTab === 'shorts' ? (
            <span className="yu-short-video-icon-solid mobile-icon">Yu</span>
          ) : (
            <span className="yu-short-video-icon mobile-icon">Yu</span>
          )}
        </li>

        {/* Subscriptions tab */}
        <li onClick={() => handleTabClick('subscriptions')}>
          {activeTab === 'subscriptions' ? (
            <span className="mobile-yu-subscriptions-icon-solid mobile-icon">subscriptions</span>
          ) : (
            <span className="mobile-yu-subscriptions-icon mobile-icon">subscriptions</span>
          )}
        </li>

        {/* User profile tab */}
        <li onClick={() => handleTabClick('profile')}>
          {isLoggedIn ? (
            <div className="yu-user-menu-profile-bottom" title='Profile' onClick={toggleRightComponent}>
              <img src={profileImage} alt="User Profile" className="profile-image" />
            </div>
          ) : (
            activeTab === 'profile' ? (
              <span className="mobile-yu-user-profile-icon-solid mobile-icon"></span>
            ) : (
              <span className="mobile-yu-user-profile-icon mobile-icon"></span>
            )
          )}
        </li>
      </ul>
    </nav>
  );
};

export default BottomNav;
