import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Static/Style/CreateChannel.css';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';

const CreateChannel = ({ onClose }) => {
    const [channelName, setChannelName] = useState('');
    const [channelDescription, setChannelDescription] = useState('');
    const [loading, setLoading] = useState(false); 
    const navigate = useNavigate();
    const { createChannel } = useChannelStore();
    const { currentUser } = useAccountStore();
    const [profileImageFile, setProfileImageFile] = useState(); 
    const [profileImagePreview, setProfileImagePreview] = useState(null);


    const generateRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const getInitials = (name) => {
        if (!name) return '';
        const names = name.split(' ');
        return names.length > 1 ? `${names[0][0]}${names[1][0]}` : names[0][0];
    };

    


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImagePreview(reader.result);
            };
            reader.readAsDataURL(file); 
        }
    };

    const handlePreviewClick = () => {
        document.getElementById('channelProfile').click(); 
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!channelName) {
            toast.error('Please provide a channel name.');
            return;
        }
        if (!channelDescription) {
            toast.error('Please provide a channel description.');
            return;
        }
        if (!currentUser?.id) {
            toast.error('User is not logged in.');
            return;
        }
    
        const userID = currentUser.id;

        const ChannelImage =`https://ui-avatars.com/api/?name=${getInitials(channelName)}&background=${generateRandomColor().replace('#', '')}&color=fff&rounded=true&size=128`;




        console.log('profileImageFile:', profileImageFile);
        console.log('ChannelImage:', ChannelImage);

        const formData = new FormData();
        formData.append('name', channelName);
        formData.append('description', channelDescription);
        formData.append('user', userID);

        let imageToUpload;

        if (profileImageFile) {
            imageToUpload = profileImageFile; 
        } else {
            const response = await fetch(ChannelImage);
            const blob = await response.blob();
            imageToUpload = new File([blob], 'default-avatar.png', { type: 'image/png' });
        }

        formData.append('profile', imageToUpload); 

        setLoading(true);
        try {
            const response = await createChannel(formData);
            console.log(response)
            toast.success('Channel created successfully!');
            localStorage.setItem('selectedId', response.id);
            onClose(); 
        } catch (error) {
            if (error.response && error.response.data) {
                const errorData = error.response.data;
                if (errorData.name && Array.isArray(errorData.name)) {
                    console.error('Channel creation error:', errorData.name);
                    toast.error(`Error: ${errorData.name[0]}`);
                } else {
                    toast.error('An unknown error occurred.');
                }
            } else {
                console.error('Channel creation error:', error.message);
                toast.error(`Error: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='create-channel-form'>
            <h2>Create Channel</h2>
            <form onSubmit={handleSubmit}>

                <div className='create-channel-form-group'>
                    <div className='channel-profile-preview' onClick={handlePreviewClick}>
                        {profileImagePreview ? (
                            <img src={profileImagePreview} alt='Channel Preview' title='Click to select another profile' />
                        ) : (
                            
                            <span>selected Profile (Optional)</span>
                            
                        )}
                    </div>
                    <input
                        id='channelProfile'
                        type='file'
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleFileChange} 
                    />
                </div>

                <div className='create-channel-form-group'>
                    <label htmlFor='channelName'>Channel Name <span style={{color:"red"}}>*</span></label>
                    <input
                        type='text'
                        id='channelName'
                        value={channelName}
                        onChange={(e) => setChannelName(e.target.value)}
                        placeholder='Enter Channel Name'
                        required
                    />
                </div>

                <div className='create-channel-form-group'>
                    <label htmlFor='channelDescription'>Description <span style={{color:"red"}}>*</span></label>
                    <textarea
                        id='channelDescription'
                        value={channelDescription}
                        onChange={(e) => setChannelDescription(e.target.value)}
                        placeholder='Channel Description'
                        required
                    />
                </div>

                <button type='submit' disabled={loading}>
                    {loading ? 'Creating...' : 'Create Channel'}
                </button>
            </form>
        </div>
    );
};

export default CreateChannel;
