import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../Static/Style/MyVideoCss/fullContentVideosHome.css';
import thumbnailImg from '../../assets/img2.jpg'
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import {useVideoStore} from '../../Store/UserStore/VideoStore'
import moment from 'moment';
import MyLoader from '../Loader/MyLoader';
import LoadingErrorPage from '../LoadingErrorPage/LoadingErrorPage';

const sampleVideos=[];


const MyVideosContent = () => {
  const [activeTab, setActiveTab] = useState('Videos');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState(new Array(sampleVideos.length).fill(false));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { getAllVideos, currentVideo } = useVideoStore();

  useEffect(() => {
    const fetchAllVideo = async () => {
      try {
        await getAllVideos();
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Videos:', error.response || error.message);
        setError('Error fetching Videos');
        setLoading(false);
      }
    };

    fetchAllVideo();
  }, [getAllVideos]);

  if (loading) {
    return <MyLoader/>;
  }

  if (error) {
    return <LoadingErrorPage />;
  }


  // const tabs = ['Videos', 'Shorts', 'Live', 'Playlists'];
  const tabs = ['Videos'];


  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedVideos(new Array(currentVideo.length).fill(!selectAll));
  };

  const toggleVideoSelection = (index) => {
    const updatedSelection = [...currentVideo];
    updatedSelection[index] = !updatedSelection[index];
    setSelectedVideos(updatedSelection);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'Videos':
        return (
          <div className='content-data-table'>
            <ul className="table-header">
              <li><input type="checkbox" checked={selectAll} onChange={toggleSelectAll} /></li>
              <li>Videos</li>
              <li>Visibility</li>
              <li>Restrictions</li>
              <li>Date</li>
              <li>Views</li>
              <li>Comments</li>
              <li>Likes</li>
            </ul>

            {currentVideo.map((video, index) => (
              <ul key={video.id} className="table-row">
                <li>
                  <input
                    type="checkbox"
                    checked={selectedVideos[index]}
                    onChange={() => toggleVideoSelection(index)}
                  />
                </li>
                <li>
                  <img src={`http://127.0.0.1:8000${video.thumbnail}`|| "no-image"} alt={video.title} className="video-thumbnail" />
                  <div>
                  <h4>{video.name}</h4>
                  <p>{video.description}</p>
                  </div>

                </li>
                <li>{video.video_type}</li>
                <li>none</li>
                <li>{moment(video.timestamp).fromNow()}</li>
                <li>0</li>
                <li>0</li>
                <li>0</li>
              </ul>
            ))}
          </div>
        );
      case 'Shorts':
        return (
          <div className='content-data-table'>
            <ul className="table-header">
              <li><input type="checkbox" checked={selectAll} onChange={toggleSelectAll} /></li>
              <li>Shorts</li>
              <li>Visibility</li>
              <li>Restrictions</li>
              <li>Date</li>
              <li>Views</li>
              <li>Comments</li>
              <li>Likes</li>
            </ul>

            {sampleVideos.map((video, index) => (
              <ul key={video.id} className="table-row">
                <li>
                  <input
                    type="checkbox"
                    checked={selectedVideos[index]}
                    onChange={() => toggleVideoSelection(index)}
                  />
                </li>
                <li>
                  <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
                  <div>
                  <h4>{video.title}</h4>
                  <p>{video.description}</p>
                  </div>

                </li>
                <li>{video.visibility}</li>
                <li>{video.restrictions}</li>
                <li>{video.date}</li>
                <li>{video.views}</li>
                <li>{video.comments}</li>
                <li>{video.likes}</li>
              </ul>
            ))}
          </div>);
      case 'Live':
        return (
          <div className='content-data-table'>
            <ul className="table-header">
              <li><input type="checkbox" checked={selectAll} onChange={toggleSelectAll} /></li>
              <li>Live</li>
              <li>Visibility</li>
              <li>Restrictions</li>
              <li>Date</li>
              <li>Views</li>
              <li>Comments</li>
              <li>Likes</li>
            </ul>

            {sampleVideos.map((video, index) => (
              <ul key={video.id} className="table-row">
                <li>
                  <input
                    type="checkbox"
                    checked={selectedVideos[index]}
                    onChange={() => toggleVideoSelection(index)}
                  />
                </li>
                <li>
                  <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
                  <div>
                  <h4>{video.title}</h4>
                  <p>{video.description}</p>
                  </div>

                </li>
                <li>{video.visibility}</li>
                <li>{video.restrictions}</li>
                <li>{video.date}</li>
                <li>{video.views}</li>
                <li>{video.comments}</li>
                <li>{video.likes}</li>
              </ul>
            ))}
          </div>);
      case 'Playlists':
        return (
          <div className='content-data-table'>
            <ul className="table-header">
              <li><input type="checkbox" checked={selectAll} onChange={toggleSelectAll} /></li>
              <li>Playlist</li>
              <li>Type</li>
              <li>Visibility</li>
              <li>Restrictions</li>
              <li>Last Update</li>
              <li>Video Count</li>
            
            </ul>

            {/* {sampleVideos.map((video, index) => (
              <ul key={video.id} className="table-row">
                <li>
                  <input
                    type="checkbox"
                    checked={selectedVideos[index]}
                    onChange={() => toggleVideoSelection(index)}
                  />
                </li>
                <li>
                  <img src={video.thumbnail} alt={video.title} className="video-thumbnail" />
                  <h5>{video.title}</h5>
                  <p>{video.description}</p>

                </li>
                <li>{video.visibility}</li>
                <li>{video.restrictions}</li>
                <li>{video.date}</li>
                <li>{video.views}</li>
                <li>{video.comments}</li>
                <li>{video.likes}</li>
              </ul>
            ))} */}
          </div>);
      default:
        return null;
    }
  };

  return (
    <>
      <div className='content-container'>
      <div className='content-title'><h3>Channel Content</h3></div>

        <div className="content-box">

          <div className="channel-tabs">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </button>
            ))}
          </div>

          {renderContent()}
        </div>
      </div>
    </>
  );
};

export default MyVideosContent;
