import React, { useState } from 'react';
import '../../Static/Style/UploadVideosCss/MultiVideos.css';
import { useVideoStore } from '../../Store/UserStore/VideoStore';
import { useChannelStore } from '../../Store/UserStore/ChannelStore';
import { useAccountStore } from '../../Store/UserStore/AccountStore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BlobServiceClient } from '@azure/storage-blob';

const MultiUploadVideos = ({ onClose }) => {
  const { uploadVideo, getSasToken, setMultipleUploadProgress, getAllVideos } = useVideoStore();
  const { currentUser } = useAccountStore();
  const { currentChannel } = useChannelStore();

  const [videos, setVideos] = useState([
    {
      videoFile: null,
      thumbnailFile:'',
      title: '',
      description: '',
      tags: '',
      category: '',
      privacy: 'public',
      uploadProgress: 0,
    },
  ]);


  const handleFileChange = (index, e) => {
    const { id, files } = e.target;
    const file = files[0];
    const updatedVideos = [...videos];

    if (id === "videoFile") {
      if (file && file.type.startsWith("video/")) {
        updatedVideos[index][id] = file;
      } else {
        toast.error(`Please select a valid video file for video ${index + 1}.`);
      }
    } else if (id === "thumbnailFile") {
      if (file && file.type.startsWith("image/")) {
        updatedVideos[index][id] = file;
      } else {
        toast.error(`Please select a valid image file for the thumbnail of video ${index + 1}.`);
      }
    }
    
    setVideos(updatedVideos);
  };

  const handleInputChange = (index, e) => {
    const { id, value } = e.target;
    const updatedVideos = [...videos];
    updatedVideos[index][id] = value;
    setVideos(updatedVideos);
  };

  const handleAddVideo = () => {
    setVideos([
      ...videos,
      {
        videoFile: null,
        thumbnailFile: null,
        title: '',
        description: '',
        tags: '',
        category: '',
        privacy: 'public',
        uploadProgress: 0,
      },
    ]);
  };

  const handleRemoveVideo = (index) => {
    if (videos.length === 1) return;
    const updatedVideos = videos.filter((_, i) => i !== index);
    setVideos(updatedVideos);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    for (const [index, video] of videos.entries()) {
      if (!video.videoFile) {
        toast.error(`Select a video file for video ${index + 1}.`);
        return;
      }
      if (!video.title) {
        toast.error(`Provide a title for video ${index + 1}.`);
        return;
      }
    }
  
    const userID = currentUser.id;
    const ChannelID = localStorage.getItem('selectedId');
  
    if (!ChannelID) {
      toast.error('Create a channel first.');
      return;
    }
  
    for (const [index, video] of videos.entries()) {
      const formData = new FormData();

      if (video.thumbnailFile) {
        formData.append('thumbnail', video.thumbnailFile);
      }

      formData.append('name', video.title);
      formData.append('description', video.description);
      formData.append('tags', video.tags);
      formData.append('category', video.category);
      formData.append('video_type', video.privacy);
      formData.append('user', userID);
      formData.append('channel', ChannelID);
  
      try {
        const sasResponse = await getSasToken({ blob_name: video.videoFile.name });
        if (sasResponse.status !== 200) {
          throw new Error('Failed to get SAS token.');
        }
  
        const { sas_token, blob_url, blob_name } = sasResponse.data;
        const blobServiceClient = new BlobServiceClient(`${blob_url}?${sas_token}`);
        const containerClient = blobServiceClient.getContainerClient('videos/videos');
        const blockBlobClient = containerClient.getBlockBlobClient(blob_name);

        // toast.info('Uploading started...');
        onClose();
  
        const file = video.videoFile;
        const fileSize = file.size;
        const blockSize = 20 * 1024 * 1024; // 20MB
        let offset = 0;
        let blockId = 0;
        let lastLoggedProgress = 0;
  
        const generateBlockId = (blockNum) => {
          const idString = blockNum.toString().padStart(5, '0');
          return btoa(idString);
        };
  
        while (offset < fileSize) {
          const chunkSize = Math.min(blockSize, fileSize - offset);
          const chunk = file.slice(offset, offset + chunkSize);
  
          await blockBlobClient.stageBlock(generateBlockId(blockId), chunk, chunkSize);
          offset += chunkSize;
          blockId += 1;
  
          const progress = Math.round((offset / fileSize) * 100);
          setMultipleUploadProgress(index, progress); 
  
          if (progress !== lastLoggedProgress) {
            console.log(`Upload progress for video ${index + 1}: ${progress}%`);
            lastLoggedProgress = progress;
          }
        }
  
        const blockList = Array.from({ length: blockId }, (_, i) => generateBlockId(i));
        await blockBlobClient.commitBlockList(blockList, {
          blobHTTPHeaders: {
            blobContentType: file.type,
          },
        });
  
        formData.append('video', `videos/videos/${blob_name}`);
        const responseVideoData =await uploadVideo(formData);
        console.log(responseVideoData);
        toast.success(`Video ${index + 1} uploaded successfully!`);
        getAllVideos();
  
      } catch (error) {
        toast.error(`Error uploading video ${index + 1}: ${error.message}`);
        console.error(`Error uploading video ${index + 1}:`, error);
      }
    }
  };
  

  
  return (
    <>
      <div className="yu-upload-popup-overlay"></div>
      <div className="yu-multi-upload-popup">
        <h2>Upload Videos</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          {videos.map((video, index) => (
            <div key={index} className="yu-multi-upload-form">
              <h3>Video {index + 1}</h3>
              <div className="yu-multi-upload-row">
                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`videoFile-${index}`}>Video File<span style={{color:"red"}}>*</span></label>
                  <input
                    type="file"
                    id="videoFile"
                    accept="video/*"
                    onChange={(e) => handleFileChange(index, e)}
                  />
                </div>

                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`thumbnailFile-${index}`}>Thumbnail<span style={{color:"red"}}>*</span></label>
                  <input
                    type="file"
                    id="thumbnailFile"
                    accept="image/*"
                    onChange={(e) => handleFileChange(index, e)}
                  />
                </div>

                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`title-${index}`}>Title<span style={{color:"red"}}>*</span></label>
                  <input
                    type="text"
                    id="title"
                    placeholder="Enter video title"
                    value={video.title}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>

                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`description-${index}`}>Description<span style={{color:"red"}}>*</span></label>
                  <input
                    type="text"
                    id="description"
                    placeholder="Video Description"
                    value={video.description}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>

                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`category-${index}`}>Category<span style={{color:"red"}}>*</span></label>
                  <input
                    type="text"
                    id="category"
                    placeholder="Enter category"
                    value={video.category}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>

                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`tags-${index}`}>Tags<span style={{color:"red"}}>*</span></label>
                  <input
                    type="text"
                    id="tags"
                    placeholder="Enter tags"
                    value={video.tags}
                    onChange={(e) => handleInputChange(index, e)}
                  />
                </div>

                <div className="yu-multi-upload-form-group">
                  <label htmlFor={`privacy-${index}`}>Privacy</label>
                  <select
                    id="privacy"
                    value={video.privacy}
                    onChange={(e) => handleInputChange(index, e)}
                  >
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                  </select>
                </div>

                <div className="yu-multi-upload-form-group-button">

                {videos.length > 1 && (
                  <button
                    className="remove-button"
                    onClick={() => handleRemoveVideo(index)}
                  >
                    Remove
                  </button>
                )}
                          </div>

              </div>
            </div>
          ))}
          

          <div className="yu-multi-upload-form-group-button">
            <div className='button-add-cancel'>
            <button type="button" className="add-button" onClick={handleAddVideo}>
              Add
            </button>
            <button type="button" className="close-button" onClick={onClose}>
              Cancel
            </button>
            </div>
            <button type="submit">Upload</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default MultiUploadVideos;
