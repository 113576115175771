import { create } from 'zustand';
import axios from 'axios';
import { url } from '../../Config'


export const useVideoStore = create((set) => ({
  currentVideo: null,
  currentAllVideo: [],
  token: null,
  currentVideoComment: null,
  currentrecommendationVideo: null,
  uploadProgress: 0,
  multipleUploadProgress: [],



  uploadVideo: async (payload,config) => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await axios.post(
        url + 'upload-video/',
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data',
          },
          ...config,
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error uploading video:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  setUploadProgress: (progress) => set({ uploadProgress: progress }),

  setMultipleUploadProgress: (index, progress) => set((state) => {
    const newUploadProgress = [...state.multipleUploadProgress];
    newUploadProgress[index] = progress; 
    return { multipleUploadProgress: newUploadProgress };
  }),



  getAllVideos: async () => {
    try {
      const response = await axios.get(url + 'get-all-video/', {},);
      set({ currentAllVideo: response.data });
      return response;
    } catch (error) {
      console.error('Error Video API:', error.response ? error.response.data : error.message);
      throw error;
    }
  },
  getSasToken: async (payload) => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await axios.post(url + 'generate-sas-token/', payload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response;
    } catch (error) {
      console.error('Error Video API:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getSingleVideos: async (videoId) => {
    try {
      const response = await axios.get(url + `get-video/${videoId}/`, {
        headers: {
          Range: 'bytes=0-1024'
        },

      });
      set({ currentVideo: response.data });
      return response;

    } catch (error) {
      console.error('Error fetching video:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  postVideoComment: async (videoId, newComment) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found');
      return;
    }

    try {
      const response = await axios.post(
        url + `comment-video/${videoId}/`,
        { comment: newComment },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error('Error posting comment:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  postLikeVideo: async (videoId, value) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      console.error('No auth token found');
      return;
    }

    try {
      const response = await axios.post(
        url + `like-video/${videoId}/`,
        { value },
        {
          headers: {
            Authorization: `Token ${token}`,
          }
        }
      );
      console.log(response)

      return response;
    } catch (error) {
      console.error('Error posting like/dislike:', error.response ? error.response.data : error.message);
      throw error;
    }
  },


  AllSearchBar: async (searchQuery) => {
    try {
      const response = await axios.get(url + `search-video/`, {
        params: {
          q: searchQuery,
        },
      });

      set({ currentSearchVideo: response.data });

      console.log(response.data)

      return response.data;
    } catch (error) {
      console.error('Error fetching search results:', error);
      throw error;
    }
  },

  saveVideo: async (payload) => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      throw new Error("No token available for authentication.");
    }

    try {
      const response = await axios.post(url + 'save-video/', payload, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error saving video:', error.response ? error.response.data : error.message);
      throw error;
    }
  },


  getCommentVideo: async (videoId) => {


    try {
      const response = await axios.get(url + `get-video-comments/${videoId}/`, {});

      return response.data;
    } catch (error) {
      console.error('Error get Comment video:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  recommendationVideos: async (videoId) => {

    try {
      const response = await axios.get(`${url}recommend-next-video/${videoId}/`, {});
      return response;
    } catch (error) {
      console.error('Error in recommend_Video-Api:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  videoLikeCount: async (videoId) => {

    try {
      const response = await axios.get(url + `count-likes/${videoId}/`, {},);
      return response;
    } catch (error) {
      console.error('Error likeCount Api :', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  videoDislikeCount: async (videoId) => {

    try {
      const response = await axios.get(url + `count-dislikes/${videoId}/`, {},);
      return response;
    } catch (error) {
      console.error('Error likeCount Api :', error.response ? error.response.data : error.message);
      throw error;
    }


  },

  videoCommentCount: async (videoId) => {

    try {
      const response = await axios.get(url + `count-comments/${videoId}/`, {},);
      return response;
    } catch (error) {
      console.error('Error likeCount Api :', error.response ? error.response.data : error.message);
      throw error;
    }

  },

  getViewCount: async (videoId) => {

    try {
      const response = await axios.get(url + `video-views/${videoId}/`, {},);
      return response;
    } catch (error) {
      console.error('Error viewCount Api :', error.response ? error.response.data : error.message);
      throw error;
    }

  },

  postViewCount: async (videoId) => {

    try {
      const response = await axios.post(url + `video-views/${videoId}/`, {},);
      return response;
    } catch (error) {
      console.error('Error viewCount Api :', error.response ? error.response.data : error.message);
      throw error;
    }

  },



}));