import React from 'react';
import '../../Static/Style/LoadingErrorCss/LoadingError.css'

const LoadingErrorPage = () => {

    const handleReload = () => {
        window.location.reload();
    };

    return (
        <div className="error-page-container">
            <div className="error-content">
                <h1 className="error-title">Something went wrong.</h1>
                <p className="error-message">We couldn't fetch the data. Please try again.</p>
                <button className="reload-button" onClick={handleReload}>
                    Retry
                </button>
            </div>
        </div>
    );
};

export default LoadingErrorPage;
