import React, { useRef, useEffect, useState } from 'react';
import '../../Static/Style/NotificationPopCss/NotificationPopup.css';
import userImg from '../../assets/user.png';
import moment from 'moment';

const NotificationPopup = ({ onClose,markAsRead,notifications,loading}) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose(); 
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);


  if(loading){

    return <div><span className='yu-spinners-small'></span></div>
  }

  return (
    <div className="notification-popup" ref={popupRef}>
      <div className="notification-header">
        <h2>Notifications</h2>
        <button className="close-btn" onClick={onClose}><span className='yu-app-cross-icon'></span></button>
      </div>

      <div className="notification-tabs">
        <span className="active-tab">View all</span>
      </div>

      <div className="notification-list">
        {notifications && notifications.length > 0 ? (
          notifications.map((notification, index) => (
            <div key={index} className='notification-item'>
              <img
                src={userImg}
                alt={`profile`}
                className="notification-profile-pic"
              />
              <div className="notification-content">
                <p>
                  <strong>{}</strong> {notification.message||notification.msg}
                </p>
                {/* {notification.data.video && <p className="notification-details">{notification.data.video}</p>} */}
                <div className="notification-time">
                  <span>{moment(notification.timestamp).fromNow()}</span>
                </div>
              </div>
              {!notification.read && <div className="unread-indicator" />}
            </div>
          ))
        ) : (
          <div className="no-notifications">No new notifications</div>
        )}
      </div>

      {notifications && notifications.length > 0 && (
        <div className="notification-footer">
          <button className="mark-all-read-btn"onClick={() => markAsRead()}>Mark all as read</button>
          <button className="view-all-btn">View all</button>
        </div>
      )}
    </div>
  );
};

export default NotificationPopup;
