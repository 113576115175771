import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../Static/Style/MyVideoCss/MyVideoMenu.css';
import userImg from '../../assets/image1.jpg';

const MyVideosMenu = () => {
  const navigate = useNavigate();
  const location = useLocation(); 

  const handleNavigation = (path) => {
    navigate(path);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <div className="menu-container">
      {/* Channel Info */}
      <div className="channel-info">
        <img 
          src={userImg} 
          alt="Channel" 
          className="channel-img"
        />
        <h3 className="channel-name">Channel Name</h3>
        <p className="user-name">@UserName</p>
      </div>

      {/* Menu Options */}
      <div className="menu-options">
        <div 
          className={`menu-item ${isActive('/yu-my-videos') ? 'active' : ''}`} 
          onClick={() => handleNavigation('/yu-my-videos')}
        >
          <span className='yu-dashbaord-icon yu-icon'></span> Dashboard
        </div>
        <div 
          className={`menu-item ${isActive('/yu-my-videos/content') ? 'active' : ''}`} 
          onClick={() => handleNavigation('/yu-my-videos/content')}
        >
          <span className='yu-video-content-icon yu-icon'></span> Content
        </div>
        <div 
          className={`menu-item ${isActive('/yu-my-videos/analytics') ? 'active' : ''}`} 
          onClick={() => handleNavigation('/yu-my-videos/analytics')}
        >
          <span className='yu-analytics-icon yu-icon'></span> Analytics
        </div>
        <div 
          className={`menu-item ${isActive('/yu-my-videos/copyright') ? 'active' : ''}`} 
          onClick={() => handleNavigation('/yu-my-videos/copyright')}
        >
          <span className='yu-copyright-icon yu-icon'></span> Copyright
        </div>
      </div>

      {/* Fixed Settings Option */}
      <div 
        className={`menu-item-settings ${isActive('/YU/settings') ? 'active' : ''}`} 
        onClick={() => handleNavigation('/yu-my-videos/settings')}
      >
        <span className='yu-setting-icon yu-icon'></span> Settings
      </div>
    </div>
  );
};

export default MyVideosMenu;
