import React from 'react';
import '../Static/Style/TermsConditionsCss/PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-box">
      <div className="privacy-policy">
        <h2>Colabrary Privacy Policy</h2>

        <h4>1. Introduction</h4>
        <p>
          Colabrary values your privacy. This Privacy Policy explains how we collect, use, and protect your personal information.
        </p>

        <h4>2. Information We Collect</h4>
        <p>
          <strong>Personal Information:</strong> Includes name, email address, and other contact details provided during registration.
        </p>
        <p>
          <strong>Usage Data:</strong> Includes information about your interactions with the platform, such as IP address, device information, and browsing activity.
        </p>

        <h4>3. How We Use Your Information</h4>
        <p>
          <strong>To Provide Services:</strong> Personal information is used to manage your account and deliver educational content.
        </p>
        <p>
          <strong>To Improve Services:</strong> Usage data helps us understand how you use the platform and enhance user experience.
        </p>
        <p>
          <strong>For Communication:</strong> We may use your contact information to send updates and notifications related to Colabrary.
        </p>

        <h4>4. Sharing Your Information</h4>
        <p>
          <strong>Third-Party Services:</strong> We do not share your personal information with third parties except as required by law or to provide our services.
        </p>
        <p>
          <strong>Legal Obligations:</strong> We may disclose information to comply with legal requirements or to protect the rights and safety of Colabrary and its users.
        </p>

        <h4>5. Data Security</h4>
        <p>
          <strong>Protection Measures:</strong> We implement industry-standard security measures to protect your data from unauthorized access or breaches.
        </p>
        <p>
          <strong>Data Retention:</strong> We retain personal information for as long as necessary to fulfill the purposes for which it was collected.
        </p>

        <h4>6. Your Rights</h4>
        <p>
          <strong>Access and Correction:</strong> You have the right to access and update your personal information.
        </p>
        <p>
          <strong>Data Deletion:</strong> You can request the deletion of your personal data, subject to legal and contractual obligations.
        </p>

        <h4>7. Cookies and Tracking Technologies</h4>
        <p>
          <strong>Use of Cookies:</strong> We use cookies to enhance your experience and track platform usage. You can manage cookie preferences through your browser settings.
        </p>

        <h4>8. Changes to Privacy Policy</h4>
        <p>
          <strong>Modifications:</strong> We may update this Privacy Policy periodically. Continued use of the platform signifies acceptance of any changes.
        </p>

        <h4>9. Contact Us</h4>
        <p>
          <strong>Inquiries:</strong> For questions or concerns about this Privacy Policy, please contact us at "info@colabrary.com".
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
