import { create } from 'zustand';
import axios from 'axios';
import { url } from '../../Config'


export const useChannelStore = create((set) => ({
  currentChannel: null,
  userChannels: [],
  authToken: null,
  isLoggedIn: false,

  createChannel: async (payload) => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      throw new Error("No token available for authentication.");
    }

    try {
      const response = await axios.post(
        url + 'create-channel/',
        payload,
        {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data',

          },
        }
      );

      return response.data;
    } catch (error) {
      console.error('Error Channel:', error.response ? error.response.data : error.message);
      throw error;
    }
  },



  getUserChannel: async () => {

    const token = localStorage.getItem('authToken');
    if (!token) {
      return;
    }

    try {
      const response = await axios.get(url + `get-user-channel/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })

      set({ userChannels: response.data, isLoggedIn: true });
      console.log(response.data)
    } catch (error) {
      console.error('Error Fetch Channel:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  getSingleChannel: async (channelId) => {
    try {
      const response = await axios.get(url + `get-channel/${channelId}/`);
      set({ currentChannel: response.data });

      return response.data;
    } catch (error) {
      console.error('Error Fetching Channel:', error.response ? error.response.data : error.message);
      throw error;
    }
  },


  getChannelVideos: async (channelId) => {
    try {
      const response = await axios.get(url + `get-channel-videos/${channelId}/`, {})

      set({ currentChannelVideo: response.data, isLoggedIn: true });
      console.log(response.data)
    } catch (error) {
      console.error('Error Channel:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  deleteChannel: async (channelId) => {
    try {
      const response = await axios.delete(url + `delete-channel/${channelId}/`);

      return response.data;
    } catch (error) {
      console.error('Error Delete Channel:', error.response ? error.response.data : error.message);
      throw error;
    }
  },


  updateChannel: async (channelId, payload) => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await axios.put(url + `update-channel/${channelId}/`, payload, {
        headers: {
          Authorization: `Token ${token}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error Edit Channel:', error.response ? error.response.data : error.message);
      throw error;
    }
  },

  subscribeChannel: async (channelId) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      throw new Error("No token available for authentication.");
    }

    try {
      const response = await axios.post(
        url + `subscribe-channel/${channelId}/`,
        {}, // No payload needed for subscription
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(response)
      return response.data;
    } catch (error) {
      console.error('Error subscribing to channel:', error.response ? error.response.data : error.message);
      throw error;
    }
  },


  channelSubscribersCount: async (channelId) => {

    try {
      const response = await axios.get(url + `count-subscribers/${channelId}/`, {},);
      return response;
    } catch (error) {
      console.error('Error likeCount Api :', error.response ? error.response.data : error.message);
      throw error;
    }

  },


}));