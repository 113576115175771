import React from 'react';
import '../../Static/Style/YuContainer.css';
import YuLeftComponent from './YuLeftComponent';

const YuContainer = () => {


    return (
        
        <div className='yu-container'>
            <div className='yu-left-component-full'>
                <YuLeftComponent
                />
            </div>
        </div>
    );
};

export default YuContainer;
